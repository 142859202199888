<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('teamReport.default[0]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <div class="h50"></div>

      <div class="box flex_sb flex_ac">
        <div class="box_item">
          <div class="name">{{$t('teamReport.team_size')}}</div>
          <div class="name1">{{listData.length}}</div>
        </div>
        <div class="shu"></div>
        <div class="box_item">
          <div class="name">{{$t('teamReport.new_this_month')}}</div>
          <div class="name1">0</div>
        </div>
      </div>

      <div class="list">
        <div v-for="(item,index) in listData" class="item flex_ac flex_sb">
          <img src="~@/assets/img/new/type_icon1.png" alt="">
           <div class="item_name"> {{item['username']}}</div>
           <div class="vip">VIP{{item['vip_level']}}</div>
        </div>
      </div>
      <div class="centerPanel">
        <van-button  @click="$router.push('/user/invite/'+UserInfo.idcode+'/'+UserInfo.idcode)">
          {{$t('teamReport.invite_friend')}}
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Service',
    components: {

    },
    props: {},
    data() {
      return {
        listData:[],
        userInfo:{},

        team_size:1126,
        new_num:3
      };
    },
    computed: {

    },
    watch: {

    },
    created() {
      this.$Model.GetUserInfo((data) => {
      if (data.code == 1) {
        this.uid = data.info.uid;
        this.userInfo = data.info;
      }
    });
    },
    mounted() {
      this.listData = [
      ];
      return
      this.getListData();
    },
    activated() {

    },
    destroyed() {

    },
    methods: {
      getListData(type) {
        var json = {};
        this.$Model.TeamReport(json,(data) => {
          this.isLoad = false;
          if (data.code == 1) {
            this.listData = data.data;
          } else {
            this.listData = [];

            this.isFinished = true;
          }
        })
      }
    },
  }
</script>
<style scoped>
.list{
  height: calc(100% - 250px);
  overflow: auto;
}
.btn{
  position: absolute;
  bottom: 20px;
  margin: 0 20px;
  width: calc(100% - 40px);
  background: linear-gradient(45deg,#69E1F1,#40C3D5);
  font-size: 18px;
  color: #fff;
  text-align: center;
  line-height: 52px;
  height: 52px;
  border-radius: 26px;
}
.PageBox {
    overflow: auto;
    padding-top: 40px;
    background: url(~@/assets/img/new/fund_bg.png) no-repeat;
    background-size:  100% auto;
    color: #333;
    background-color: #F5F8FF;
    padding-top: 0;
  }
  .ScrollBox{
    background: url(~@/assets/img/new/fund_bg.png) no-repeat;
    background-size:  100% auto;
    color: #333;
    background-color: #F5F8FF !important;
  }
  .PageBox>>> .van-icon{
    color: #fff !important;
  }
  .PageBox>>> .van-nav-bar{
    background-color: #0000 !important;
    background: url(~@/assets/img/new/fund_bg.png) no-repeat;
    background-size:  100% auto;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#fff;
    font-weight: bold;
  }


  .Menu2item1 {
    margin-top: 10px;
    background-color: #24303C;
    line-height: 25px;
    margin-left: 10px;
    width: 48%;
    text-align: center;
    padding: 10px;
  }

  .ScrollBox{
    background-color: #0F1A29;
  }

  .item{
    background: #fff;
    margin: 10px 20px;
    height: 52px;
    color: #000;
    font-size: 16px;
    border-radius: 10px;
    padding: 15px;
  }
  .item img{
    height: 40px;
    width: 40px;
    margin-right: 10px;
    border-radius: 20px;
  }
  .item_name{
    font-size: 18px;
  }
  .vip{
    color: #999;
    font-size: 16px;
    margin-left: auto;
  }
  .box{
    margin: 10px 20px;
    width: calc(100% - 40px);
    background: #fff;
    border-radius: 16px;

  }
  .box_item{
    width: calc(50% - 2px);
    padding: 25px 0;
    text-align:  center;
  }
  .shu{
    height: 60px;
    width: 1px;
    background: #f1f1f1;
  }
  .name{
    color: #999;
  }
  .name1{
    font-size: 24px;
    font-weight: 600;
    color: #000;
    margin-top: 5px;
  }
</style>
